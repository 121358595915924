<template>
    <div>
      <PDFViewer
        :source="url"
        style="height: 100vh; width: 100vw"
      />
    </div>
  </template>
  
  <script>
//   import PDFViewer from 'pdf-viewer-vue/dist/vue2-pdf-viewer'
  export default {
      props:["url"],
    
  components:{
      PDFViewer: () => import('pdf-viewer-vue/dist/vue2-pdf-viewer'),
      // VuePdfApp,
  
  }
  }
  </script>
  
  <style>
  
  </style>